section.park-introduction {
    main {
        @include media-breakpoint-up(md) {
            padding-right: $section-padding;
        }
    }

    aside {
        border-left: 2px solid darken($grey, 15);

        @include media-breakpoint-up(md) {
            padding-left: $section-padding;
        }

        h3 {
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 2px;
            font-size: 1.5rem;
            margin-top: 35px;
        }

        h5 {
            color: $green;
            font-weight: 500;
            font-size: 1.2rem;
            text-transform: uppercase;
            font-family: $sans;
            margin: 0;
        }
    }
}
