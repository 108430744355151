section.park-carousel {
    background-color: $grey;
    position: relative;
    padding: 0;

    div.parks {
        position: relative;
        z-index: 1;

        article.park {
            $icon_size: 50px;
            $center_offset: 25px;
            $park_padding: 30px;
            $park_padding_lg: $park_padding - 15;
            $park_padding_md: $park_padding - 20;
            text-align: center;
            margin: 0 $park_padding;
            margin-top: ($icon_size / 2) + $center_offset;
            margin-bottom: $center_offset + $park_padding;
            max-width: 1000px;
            width: 100vw;
            opacity: 0.5;
            transition: all 0.3s ease;
            position: relative;

            @include media-breakpoint-down(lg) {
                $center_offset: 0;
                width: auto;
                max-width: 80vw;
                margin: 0 $park_padding_lg;
                margin-top: ($icon_size / 2) + $center_offset;
                margin-bottom: $center_offset + $park_padding_lg;
            }

            @include media-breakpoint-down(md) {
                $center_offset: 0;
                max-width: 90vw;
                margin: 0 $park_padding_md;
                margin-top: ($icon_size / 2) + $center_offset;
                margin-bottom: $center_offset + $park_padding_md;
            }

            figure {
                position: relative;
                border-radius: $border-radius $border-radius 0 0;
                height: 12vw;
                min-height: 150px;
                width: 100%;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;

                a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            main {
                background-color: $white;
                padding: 40px 8% 120px;
                border-bottom: $border-radius solid $green;
                border-radius: 0 0 $border-radius $border-radius;
                //height: 100%;
                display: flex;
                flex: 1;
                flex-flow: column;

                @include media-breakpoint-down(lg) {
                    padding: 30px 30px 125px;
                }

                @include media-breakpoint-down(sm) {
                    padding: 20px 20px 145px;
                }

                header {
                    font-family: $serif;
                    color: $dark-grey;
                    text-transform: uppercase;
                    position: relative;

                    h3 {
                        font-weight: 500;
                        font-size: 3rem;
                        line-height: 1.2em;
                        margin: 0;
                        padding: 0 15px 5px;
                        letter-spacing: 2px;

                        a {
                            color: $text-color;
                        }

                        @include media-breakpoint-down(lg) {
                            font-size: 2.5rem;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 2rem;
                        }
                    }

                    h4 {
                        font-family: $sans;
                        font-weight: normal;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        margin: 0;
                        font-size: 0.95rem;
                    }

                    h3,
                    h4 {
                        position: relative;
                        z-index: 2;
                    }

                    &:after {
                        font-size: 7.5rem;

                        @include media-breakpoint-down(sm) {
                            font-size: 4rem;
                        }
                    }
                }

                aside.activity-icons {
                    text-align: center;
                    vertical-align: middle;
                    margin: 40px 0 0;

                    a {
                        display: inline-block;
                        width: 35px;
                        margin-right: 4px;

                        img {
                            display: block;
                            width: 100%;
                        }
                    }

                    span {
                        &.divider {
                            @include media-breakpoint-down(sm) {
                                display: none;
                            }
                        }

                        &.park-size {
                            text-transform: uppercase;
                            position: relative;
                            top: 2px;
                            font-family: $sans;

                            @include media-breakpoint-down(sm) {
                                display: block;
                                margin: 10px 0 0;
                            }
                        }
                    }

                    a,
                    span {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    @include media-breakpoint-down(sm) {
                        margin: 20px 0 0;
                    }
                }

                p {
                    margin: 40px 0 0;

                    a {
                        font-family: $sans;
                        text-transform: uppercase;
                        font-weight: bold;
                    }

                    @include media-breakpoint-down(sm) {
                        margin: 20px 0 0;
                    }
                }
            }

            footer {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: $park_padding 0;

                @include media-breakpoint-down(md) {
                    padding: $park_padding_md 0;
                }

                a.btn {
                    margin: 0 15px;

                    @include media-breakpoint-down(sm) {
                        display: block;
                        margin-bottom: 10px;
                    }
                }
            }

            span.divider {
                margin: 0 10px;
            }

            &.slick-center {
                //margin: 0 ($park_padding - $center_offset / 2);
            }

            &.slick-active {
                opacity: 1;
            }

            &:hover {
                opacity: 1;
                transform: scale(1.05);

                @include media-breakpoint-down(lg) {
                    transform: none;
                }
            }

            &,
            &:focus {
                outline: none;
            }
        }
    }
}
