section.find-a-park {
    main.views {
        aside.toolbar {
            @include media-breakpoint-down(md) {
                //text-align: center;
            }

            p {
                line-height: 1em;
                font-size: 1rem;
                margin-bottom: 10px;

                a {
                    //font-family: $sans;
                    //font-size: 1.1rem;
                    //vertical-align: middle;
                }
            }
        }

        .search-view {
            display: none;

            &.list {
                ul.search-results {
                    li.search-result {
                        h4 {
                            margin: 0;
                        }

                        ul.icon-list {
                            li {
                                margin-top: 5px;
                                padding: 0;

                                img {
                                    width: 35px;
                                }
                            }
                        }
                    }
                }
            }

            &.map {
                text-align: center;

                figure#mapbox {
                    height: 600px;
                    margin-bottom: $section-padding / 2;
                }
            }

            &.active {
                display: block;
            }
        }
    }

    aside.filter-and-sort {
        @include media-breakpoint-down(md) {
            margin-bottom: $section-padding-md;
        }

        h3 {
            font-size: 1.6rem;

            @include media-breakpoint-down(md) {
                font-size: 1.4rem;
            }
        }

        section.sort-group {
            margin-bottom: $section-padding / 2;

            span {
                background-color: $white;
                border-radius: $border-radius;
                overflow: hidden;
                position: relative;
                display: block;

                input,
                label {
                    line-height: 1;
                    padding: 15px;
                }

                input {
                    border: none;
                    font-size: 1.1rem;
                }

                label {
                    cursor: pointer;
                    margin: 0;
                    display: block;
                    color: $white;
                    position: absolute;
                    background-color: $text-color;
                    top: 0;
                    right: 0;
                    bottom: 0;

                    i {
                        vertical-align: middle;
                        display: inline;
                        font-size: 1.2em;
                    }

                    input {
                        display: none;
                    }

                    &:hover,
                    &.active {
                        background-color: $green;
                    }
                }
            }
        }

        section.filter-group {
            margin-bottom: 25px;

            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
            }

            h4 {
                font-family: $sans;
                text-transform: uppercase;
                color: $orange;
                margin: 0;

                @include media-breakpoint-down(md) {
                    background-color: $white;
                    padding: 10px 15px 8px;
                }

                &:after {
                    @include media-breakpoint-down(md) {
                        content: "\f078";
                        float: right;
                        font-family: "FontAwesome";
                    }
                }
            }

            ul {
                font-size: 1.1rem;

                @include media-breakpoint-down(md) {
                    padding: 10px;
                    background-color: $white;
                }

                li {
                    display: inline-block;
                    padding: 0;

                    label {
                        input[type="checkbox"] {
                            visibility: hidden;
                            position: absolute;
                            left: -9999px;

                            &:checked+span {
                                background-color: $blue;
                                color: #fff;

                                i {
                                    &:before {
                                        content: "\f05d";
                                    }
                                }
                            }
                        }

                        span {
                            cursor: pointer;
                            display: block;
                            line-height: 1;
                            margin: 0;
                            padding: 0;
                            border-radius: 13px;
                            background-color: darken($grey, 8);
                            color: rgba($text-color, 0.6);
                            padding: 5px 10px;
                            padding-left: 30px;
                            position: relative;

                            i {
                                position: absolute;
                                top: 4px;
                                left: 8px;
                            }
                        }
                    }
                }
            }
        }

        button {
            @include media-breakpoint-only(md) {
                margin-bottom: 10px;
            }
        }

        .descriptive-search {
            background-color: $white;
            padding: 15px;
            border-radius: $border-radius;
            border: 1px solid darken($white, 15);
            margin-bottom: $section-padding / 2;

            p {
                margin: 0;
            }
        }
    }
}