section.activity-carousel {
    background-color: $grey;
    position: relative;
    padding: 0;

    div.activities {
        position: relative;
        z-index: 1;

        article.activity {
            $icon_size: 130px;
            $center_offset: 25px;
            $activity_padding: 30px;
            $activity_padding_lg: $activity_padding - 15;
            $activity_padding_md: $activity_padding - 20;
            text-align: center;
            margin: 0 $activity_padding;
            margin-top: ($icon_size / 2) + $center_offset;
            margin-bottom: $center_offset + $activity_padding;
            min-width: $icon_size * 2.5 + $activity_padding;
            max-width: $icon_size * 3 + $activity_padding;
            width: 25vw;
            opacity: 0.5;
            transition: all 0.3s ease;
            position: relative;

            @include media-breakpoint-down(lg) {
                width: 36vw;
                margin: 0 $activity_padding_lg;
                margin-top: ($icon_size / 2) + $center_offset;
                margin-bottom: $center_offset + $activity_padding_lg;
                max-width: $icon_size * 4 + $activity_padding_lg;
                min-width: 0;
            }

            @include media-breakpoint-down(md) {
                width: auto;
                margin: 0 $activity_padding_md;
                margin-top: ($icon_size / 2) + $center_offset;
                margin-bottom: $center_offset + $activity_padding_md;
                max-width: 80vw;
            }

            @include media-breakpoint-down(sm) {
                //width: 60vw;
                //max-width: 100%;
                margin-top: ($icon_size / 3) + $center_offset;
            }

            header {
                font-family: $serif;
                color: #fff;
                text-transform: uppercase;
                padding: 0 15px 5px;
                border-radius: $border-radius $border-radius 0 0;

                img.icon {
                    //position: absolute;
                    width: $icon_size;
                    display: inline-block;
                    margin: 0 auto;
                    margin-top: -($icon_size / 2);

                    @include media-breakpoint-down(lg) {
                        $icon_size: 110px;
                        width: $icon_size;
                    }
                }

                h3 {
                    position: absolute;
                    left: 0;
                    right: 0;
                    font-weight: normal;
                    font-size: 1.7rem;
                    line-height: 1.2em;
                    margin: 0;
                    padding: 0 15px 10px;
                    letter-spacing: 2px;
                    z-index: 5;

                    &,
                    a {
                        color: rgba(#000, 0.45);
                    }

                    @include media-breakpoint-down(lg) {
                        //left: $activity_padding_lg;
                        //right: $activity_padding_lg;
                    }
                }
            }

            figure {
                img {
                    display: block;
                    width: 100%;
                }
            }

            main {
                background-color: $white;
                padding: 50px 40px 60px;
                border-radius: 0 0 $border-radius $border-radius;
                border-bottom: $border-radius solid $green;
                border-color: var(--border-color);
                position: relative;
                //height: 100%;
                display: flex;
                flex: 1;
                flex-flow: column;

                @include media-breakpoint-down(lg) {
                    padding: 30px;
                }

                h4 {
                    font-family: $sans;
                    font-weight: normal;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    margin-bottom: $activity_padding;
                    font-size: 0.95rem;

                    @include media-breakpoint-down(lg) {
                        margin-bottom: $activity_padding_lg;
                    }
                }

                p {
                    margin: 0 0 $activity_padding;

                    @include media-breakpoint-down(lg) {
                        padding-bottom: $activity_padding_lg;
                    }
                }

                footer {
                    padding: $activity_padding 0;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    font-size: 1.2rem;

                    @include media-breakpoint-down(lg) {
                        padding: $activity_padding_lg 0;
                    }

                    a {
                        font-family: $sans;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                }
            }

            &.slick-active {
                opacity: 1;

                @include media-breakpoint-down(lg) {
                    opacity: 0.5;
                }

                @include media-breakpoint-down(sm) {
                    opacity: 1;
                }
            }

            &.slick-current {
                //margin: 0 ($activity_padding - $center_offset / 2);

                @include media-breakpoint-down(lg) {
                    opacity: 1;
                }
            }

            &:hover {
                transform: scale(1.05);

                @include media-breakpoint-down(md) {
                    transform: none;
                }
            }

            &,
            &:focus {
                outline: none;
            }
        }
    }
}
