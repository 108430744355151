/*!
Theme Name: Geauga Parks District
Theme URI: http://www.company119.com
Description:
Author: Company 119
Author URI: http://www.company119.com
Version: 1.3
Template: co119-boilerplate
*/

@import "init/vars";

@import "../../libs/bootstrap/bootstrap-grid";
@import "../../libs/bootstrap/bootstrap-reboot";
@import "../../libs/bootstrap/utilities/spacing";

@import "init/mixins";

@import "global";

@import "header";
@import "main";
@import "footer";

@import "pages/parks";
@import "pages/search-results";

@import "components/buttons";
@import "components/icons";
@import "components/transition";
@import "components/modal";
@import "components/forms";
@import "components/search";
@import "components/loop";
@import "components/interstitial";
@import "components/slideshow";

@import "sections/hero";
@import "sections/start-your-adventure";
@import "sections/activity-carousel";
@import "sections/park-carousel";
@import "sections/half-content";
@import "sections/centered-content";
@import "sections/instagram-feed";
@import "sections/flippable-cards";
@import "sections/park-introduction";
@import "sections/upcoming-events";
@import "sections/map";
@import "sections/find-a-park-search";
@import "ie-overrides";
