/* Body */

body {
    font-family: $serif;
    font-size: 16px;
    background-color: $grey;
    color: $text-color;
    -webkit-font-smoothing: antialiased;

    * {
        &:focus,
        &:hover {
            outline: none;
        }
    }

    ul {
        &.no-style {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

    figure {
        margin: 0;

        &.photo-credit {
            position: relative;

            &:after {
                text-shadow: 0 0 5px rgba(#000, 0.95), 0 0 10px rgba(#000, 0.75);
                position: absolute;
                bottom: 10px;
                right: 10px;
                font-size: 0.8rem;
                font-family: $sans;
                color: #fff;
                text-transform: uppercase;
                font-weight: 400;
                line-height: 1;
                content: "Photo: " var(--photo-credit);

                @include media-breakpoint-down(sm) {
                    font-size: 0.7rem;
                }
            }
        }
    }

    img {
        max-width: 100%;

        &.alignleft {
            float: left;
            margin-right: 20px;
        }

        &.alignright {
            float: right;
            margin-left: 20px;
        }

        &.alignleft,
        &.alignright {
            margin-bottom: 20px;
        }
    }

    a {
        color: $blue;
        text-decoration: none;

        &.jump-to-top {
            $btn-size: 65px;
            cursor: pointer;
            width: $btn-size;
            height: $btn-size;
            line-height: $btn-size;
            font-size: $btn-size / 2.5;
            text-align: center;
            position: fixed;
            bottom: -($btn-size / 2);
            right: $btn-size / 2;
            background-color: $text-color;
            color: $white !important;
            border-radius: $border-radius;
            z-index: 9999999;
            visibility: hidden;
            opacity: 0;
            transition: all 0.4s ease;

            @include media-breakpoint-down(md) {
                $btn-size-md: $btn-size / 1.5;
                width: $btn-size-md;
                height: $btn-size-md;
                line-height: $btn-size-md;
                font-size: $btn-size-md / 3;
                bottom: -($btn-size-md / 2);
                right: $btn-size-md / 2;
            }

            i {
                position: relative;
                top: -3px;

                @include media-breakpoint-down(md) {
                    top: 0;
                }
            }

            &.show {
                bottom: $btn-size / 2;
                visibility: visible;
                opacity: 1;
            }

            &:hover {
                transform: scale(1.05);
            }
        }

        &.phone-link {
            font-family: "Amiko", sans-serif;
        }

        &.mobile-toggle-button {
            display: none !important;

            @include media-breakpoint-down(md) {
                display: inline-block !important;

                + ul.menu {
                    display: none;
                }
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    i[class*="gpd-"] {
        vertical-align: middle;
        line-height: 1;
        display: inline-block;
    }

    &.noscroll {
        overflow: hidden;
        width: 100%;
        height: inherit;
        position: fixed;
    }

    .hidden {
        display: none;
    }

    .bg-cover {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .group {
        zoom: 1; /* For IE 6/7 (trigger hasLayout) */

        &:before,
        &:after {
            content: "";
            display: table;
        }

        &:after {
            clear: both;
        }
    }
}

html,
body {
    height: 100%;
}
