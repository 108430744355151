/*** Fonts ***/
@import url("https://fonts.googleapis.com/css?family=Amiko:400,600,700|Permanent+Marker|Zilla+Slab:400,500,600,700");
$sans: "Amiko", sans-serif;
$serif: "Zilla Slab", serif;
$handwritten: "Permanent Marker", cursive;

/*** Colors ***/
$green: #5cb050;
$orange: #ff9b31;
$blue: #2ca0dc;
$skyblue: #73bde2;
$aqua: #3ac9de;
$red: #e43c3d;
$gold: #ffcc41;
$yellow: #e8b74d;
$pink: #e8a6d9;
$brown: #9a7a57;
$teal: #58d6a2;

$grey: #efedeb;
$medium-grey: #969191;
$dark-grey: #565757;

$primary-color: $green;
$secondary-color: $orange;
$text-color: #555858;

/*** Template Sizes ***/
$header-height: 125px;
$section-padding: 60px;
$section-padding-md: 45px;
$border-radius: 5px;

/*** Breakpoints ***/
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px
);
