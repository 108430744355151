section {
    &.park-details {
        text-align: center;
        text-transform: uppercase;
        padding: 50px 0;

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        .detail {
            margin: 0 40px;
            padding: 0 0 55px;
            position: relative;

            @include media-breakpoint-down(md) {
                margin: ($section-padding-md) 0 0;
                padding: 0;
            }

            h3 {
                font-size: 1.5rem;
                font-weight: 500;
                letter-spacing: 2px;

                i {
                    font-size: 1.1em;
                }
            }

            p {
                font-family: $sans;
                font-size: 0.9rem;
                opacity: 0.6;
            }

            footer {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;

                @include media-breakpoint-down(md) {
                    position: static;
                }
            }

            &.hours {
                #modal-hours {
                    text-align: left;

                    h4 {
                        margin: 0;
                    }

                    p {
                        padding: 5px 0 10px;
                    }

                    ul {
                        padding: 10px 0 0;
                    }
                }
            }

            + .detail {
                &:before {
                    content: "";
                    width: 2px;
                    background-color: darken($grey, 15);
                    position: absolute;
                    top: -10px;
                    bottom: -10px;
                    right: 100%;
                    margin-right: 40px;
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
