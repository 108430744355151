/* Footer */

footer.main {
	background-color: $green;
	position: relative;

	figure.transition {
		position: absolute;
		bottom: 100%;
		left: 0;
		right: 0;

		svg {
			max-height: 71px;

			path {
				fill: $green;
			}
		}
	}

	section.footer-content {
		padding-top: 50px;
		padding-bottom: 50px;

		&,
		a {
			color: #fff;
		}

		.contact {
			text-align: center;

			a.logo {
				width: 125px;
				margin: 0 auto;
				display: block;
			}

			h3 {
				text-transform: uppercase;
			}

			p {
				&,
				* {
					vertical-align: middle;
				}

				img {
					height: 22px;
				}
			}
		}

		.connect {
			ul.social-icons {
				margin: 30px 0;

				li {
					width: 40px;
					display: inline-block;

					+ li {
						margin-left: 10px;
					}
				}

				@include media-breakpoint-down(sm) {
					margin: 15px 0;
				}
			}
		}

		.subscribe {
			form {
				&,
				* {
					vertical-align: bottom;
				}

				input {
					&[type="email"] {
						background-color: transparent;
						border: none;
						border-bottom: 2px solid rgba(#fff, 0.4);
						font-size: 1.2rem;
						max-width: 325px;
						min-width: 240px;
						width: 25vw;
						margin-right: 10px;
						letter-spacing: 2px;
						color: #fff;

						@include placeholder {
							color: #fff;
							text-transform: uppercase;
							font-family: $serif;
							font-weight: 600;

							@include media-breakpoint-down(sm) {
								opacity: 0.6;
							}
						}

						@include media-breakpoint-down(sm) {
							margin: 0 0 20px;
							text-align: center;
							width: 90%;
						}
					}

					&,
					&:focus {
						outline: none;
					}
				}
			}
		}

		.connect,
		.subscribe {
			h2 {
				text-transform: uppercase;
				font-weight: 500;
				font-size: 2.5rem;
				letter-spacing: 2px;
				margin: 20px 0 5px;
				line-height: 1;
			}

			h3 {
				color: darken($green, 20);
				font-family: $sans;
				font-weight: 600;
				font-size: 1.2rem;
				text-transform: uppercase;
			}

			@include media-breakpoint-down(sm) {
				text-align: center;
			}
		}
	}

	aside.copyright {
		background-color: rgba(#000, 0.1);
		text-align: center;
		padding: 20px 0;
		color: #fff;
		font-family: $serif;
		text-transform: uppercase;

		a {
			color: #fff;
		}

		span {
			+ span {
				&:before {
					content: "•";
					padding: 0 10px;
				}
			}
		}
	}
}
