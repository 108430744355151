main.app {
    .gform_wrapper {
        form {
            .gf_progressbar_percentage {
                background: none;
                background-color: $green;
            }

            .gform_body {
                ul.gform_fields {
                    li.gfield {
                        margin: 0 0 15px;
                        padding: 0;
                        background-color: #fff;
                        padding: 20px;
                        border-radius: 4px;
                        border-bottom: 4px solid $blue;

                        label {
                            &.gfield_label {
                                font-family: $sans;
                                text-transform: uppercase;
                                margin: 0;
                            }
                        }

                        .ginput_container {
                            margin: 3px 0 0;

                            input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
                            select,
                            textarea {
                                padding: 5px 10px;
                                border: 1px solid #ccc;
                                border-radius: 3px;
                                background-color: darken(#fff, 2);

                                &:focus {
                                    border-color: $green;
                                }
                            }

                            input {
                                &.medium {
                                    width: 100%;
                                }
                            }

                            select {
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;

                                option {
                                    padding: 0;
                                    line-height: 1;
                                    margin: 0;
                                }
                            }

                            ul.gfield_checkbox,
                            ul.gfield_radio {
                                li {
                                    padding: 5px 0 !important;

                                    &,
                                    label {
                                        line-height: 1.2em;
                                    }

                                    input[type="checkbox"],
                                    input[type="radio"] {
                                        position: relative;
                                        //top: -3px;
                                        margin-right: 5px;

                                        &[value="gf_other_choice"] {
                                            top: -3px;
                                            margin-top: 0;
                                        }

                                        + input[type="text"] {
                                            width: 75%;
                                        }
                                    }
                                }
                            }
                        }

                        &.three-column {
                            .ginput_container {
                                ul.gfield_checkbox,
                                ul.gfield_radio {
                                    margin-bottom: 0;

                                    li {
                                        margin: 0;
                                        display: inline-block;
                                        width: 50%;
                                        vertical-align: top;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .gform_footer,
            .gform_page_footer {
                text-align: right;

                input {
                    &[type="button"],
                    &[type="submit"] {
                        background-color: $green;
                        color: $white;
                        font-family: $sans;
                        text-transform: uppercase;
                        border-radius: $border-radius;
                        border: none;
                        padding: 10px 35px 8px;
                        font-size: 1.2rem;
                        margin: 0;

                        &.gform_previous_button {
                            float: left;
                            background-color: darken($white, 25);
                        }
                    }
                }
            }
        }
    }
}
