section.half-content {
    background-color: $grey;
    position: relative;

    main.content {
        z-index: 2;
        position: relative;
        min-height: 650px;

        @include media-breakpoint-down(lg) {
            min-height: 0;
            margin-top: $section-padding;
        }

        @include media-breakpoint-down(md) {
            margin-top: $section-padding-md;
        }

        article {
            margin-bottom: 40px;

            p {
                line-height: 1.8em;
                margin: 0;

                + p {
                    margin-top: 20px;
                }
            }

            &.truncate-text {
                transition: all 0.4s ease;
            }
        }
    }

    aside.media-panel {
        z-index: 1;
        padding: 0;

        &.image {
            //min-height: 650px;
            max-height: 650px;
            overflow: hidden;

            figure {
                height: 100%;
                background-size: cover;
                background-position: center center;
                position: relative;

                &.photo-credit {
                    &:after {
                        bottom: 90px;
                        left: 130px;
                        right: auto;
                    }
                }

                &.add-border {
                    &:before {
                        content: "";
                        position: absolute;
                        top: -2px;
                        left: -2px;
                        right: -2px;
                        bottom: -2px;
                        background-image: url("assets/dist/images/grungy-image-mask.svg");
                        background-position: right bottom;
                        background-repeat: no-repeat;
                        background-size: cover;

                        @include media-breakpoint-down(md) {
                            background-position: center center !important;
                        }
                    }
                }
            }

            &.order-lg-12 {
                figure {
                    &.photo-credit {
                        &:after {
                            bottom: 75px;
                            right: 75px;
                            left: auto;
                        }
                    }

                    &:before {
                        background-position: left bottom;
                    }
                }
            }
        }

        &.icon-grid {
            figure {
                .icons {
                    padding-right: 35px;
                    text-align: center;

                    .icon {
                        padding: 45px;

                        @include media-breakpoint-down(lg) {
                            padding: 25px;
                        }

                        img {
                            height: 90px;
                            margin-bottom: 10px;

                            @include media-breakpoint-down(sm) {
                                height: 75px;
                            }
                        }

                        h5 {
                            text-transform: uppercase;
                            font-size: 2.5rem;
                            font-weight: 500;
                            line-height: 1;
                            letter-spacing: 2px;
                            margin: 10px 0 0;
                            font-family: "Amiko", sans-serif;

                            small {
                                display: block;
                                font-size: 0.4em;
                                font-weight: normal;
                                letter-spacing: 1px;
                                margin: 5px 0 0;
                            }
                        }

                        @include media-breakpoint-down(sm) {
                            padding: 20px;
                        }
                    }

                    @include media-breakpoint-down(sm) {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    figure.watermark {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 50%;
        background-size: contain;
        background-position: left top;
        background-repeat: no-repeat;
        z-index: 1;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .row {
        //position: relative;
        z-index: 2;
    }

    &.image {
        aside.media-panel {
            position: absolute;
            top: 30px;
            bottom: 30px;
            left: 0;
            right: 50%;

            @include media-breakpoint-down(md) {
                position: static;
                height: 300px;
                margin-bottom: $section-padding-md;
            }
        }

        &.content-left {
            aside.media-panel {
                right: 0;
                left: 50%;
            }
        }
    }

    &.icon-grid {
        padding: 50px 0;
    }
}
