section.centered-content {
    text-align: center;

    main {
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
