/* Content */

main {
	p,
	ul,
	ol,
	blockquote {
		font-size: 1.2rem;

		strong {
			font-weight: 700;
		}
	}

	ul,
	ol {
		line-height: 1.3em;

		li {
			padding: 5px 0;
		}
	}


	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&[class*="title-"] {
			color: $green;
			font-weight: 500;
			text-transform: uppercase;
			position: relative;
			z-index: 0;
			line-height: 1;
			font-size: 3.9rem;
			letter-spacing: 2px;
			margin-bottom: $section-padding / 1.5;

			@include media-breakpoint-down(md) {
				font-size: 2.8rem;
			}

			small {
				display: block;
				font-family: $sans;
				color: $text-color;
				font-weight: 600;
				font-size: 0.5em;

				@include media-breakpoint-down(md) {
					font-size: 0.6em;
				}
			}

			&.title-md {
				font-size: 3rem;
				margin-bottom: $section-padding / 2;
			}

			&.title-sm {
				font-size: 2.2rem;
				margin-bottom: $section-padding / 2.5;

				@include media-breakpoint-down(lg) {
					font-size: 2rem;
				}
			}

			&.centered {
				text-align: center;
			}

			+ article {
				position: relative;
				z-index: 10;
			}
		}

		&.font-serif {
			letter-spacing: 0.1em;
			font-weight: 500;
			text-transform: uppercase;
		}

		&.font-handwritten {
		}

		&.font-sans {
			font-family: $sans;
			font-weight: 400;
		}
	}

	h3 {
		font-size: 1.35em;
	}

	.has-watermark-text {
		position: relative;

		&:after {
			content: attr(data-watermark);
			position: absolute;
			font-family: $handwritten;
			top: 50%;
			left: 0;
			text-transform: uppercase;
			color: rgba($text-color, 0.05);
			font-size: 5rem;
			line-height: 0;
			z-index: 0;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		&.centered,
		&.centered-watermark {
			&:after {
				right: 0;
				text-align: center;
			}
		}
	}

	.container {
		&.expanded {
			//padding: 0 14%;
			max-width: 1400px;
		}
	}
}

section.content-band {
	padding: $section-padding 0;

	@include media-breakpoint-down(md) {
		padding: $section-padding-md 0;
	}

	&:last-child {
		padding-bottom: $section-padding * 2;

		@include media-breakpoint-down(md) {
			padding-bottom: $section-padding-md * 2;
		}
	}
}
