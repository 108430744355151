section.start-your-adventure {
    background-color: $grey;
    text-align: center;
    padding-top: 75px;

    h2.title-lg {
        position: relative;

        &:after {
            content: "Get Going";
            font-size: 9.5rem;

            @include media-breakpoint-down(md) {
                font-size: 6rem;
                top: 0;
            }

            @include media-breakpoint-down(sm) {
                font-size: 3.5rem;
            }
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 25px;
        }
    }

    form {
        font-size: 1.1rem;
        text-transform: uppercase;
        position: relative;
        z-index: 9999;

        input {
            background-color: transparent;
            border: none;
            border-bottom: 2px solid rgba($text-color, 0.2);
            font-size: 2rem;
            margin: 0 10px;
            line-height: 1;
            width: 265px;
            font-family: $handwritten;
            color: $text-color;

            @include placeholder {
                opacity: 0.5;
                text-transform: uppercase;
            }

            &,
            &:focus,
            &:active {
                outline: none;
            }

            @include media-breakpoint-down(sm) {
                display: block;
                width: 90%;
                text-align: center;
                margin: 5px auto 10px;
            }
        }

        .fuzzyResults {
            text-align: left;
            z-index: 10000;

            > div {
                padding: 5px 10px;

                &.selected {
                    background-color: $green;
                    color: #fff;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }
}
