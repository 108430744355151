figure.transition {
	width: 100%;

	svg {
	    line-height: 0;
	    display: block;
	
	    path {
	        fill: $grey;
	    }
	}
}