ul.loop-items {
    li.loop-item {
        margin-top: $section-padding / 2;
        background-color: #fff;
        padding: $section-padding / 2;
        border-radius: 4px;
        border-bottom: 4px solid $purple;

        h1 {
            font-size: 3rem;
            line-height: 1em;
            margin-bottom: $section-padding / 2;
        }

        figure.photos {
            column-count: 2;
            column-gap: 15px;

            a {
                display: inline-block;
                border: 3px solid darken($white, 15);
                margin-bottom: 15px;
            }

            img {
                display: block;
            }

            &.three-column {
                column-count: 3;
            }
        }
    }

    +.navigation {
        margin-top: 25px;
    }
}

.navigation {
    .next-posts {
        float: left;
    }

    .prev-posts {
        float: right;
    }
}