section.instagram-feed {
    text-align: center;
    background-color: $grey;

    .feed {
        border-bottom: 5px solid $green;

        figure.instaphoto {
            overflow: hidden;
            float: left;
            width: 20%;
            padding-bottom: 20%;
            position: relative;
            z-index: 1;

            a {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                transition: all 0.3s ease;
                background-size: cover;
                background-repeat: no-repeat;
            }

            &:hover {
                a {
                    transform: scale(1.05);
                    z-index: 2;
                }
            }

            @include media-breakpoint-down(md) {
                width: 25%;
                padding-bottom: 25%;

                &:nth-last-child(-n + 2) {
                    display: none;
                }
            }

            @include media-breakpoint-down(sm) {
                width: 50%;
                padding-bottom: 50%;
            }
        }
    }

    a.btn {
        margin: 50px 0 0;
    }
}
