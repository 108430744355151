section.flippable-cards {
    .flip-cards {
        .flip-card {
            .flip-card-box {
                margin: 0 20px;
                border-radius: $border-radius;
                overflow: hidden;
                border-bottom: $border-radius solid $orange;
                transition: transform 0.3s ease;
                height: 100%;
                background-color: $white;
                position: relative;

                @include media-breakpoint-down(md) {
                    margin: 0;
                }

                article {
                    text-align: center;
                    color: lighten($text-color, 10);
                    padding: 30px 15%;

                    h3 {
                        font-weight: 500;
                        text-transform: uppercase;
                        font-size: 1.9rem;
                        letter-spacing: 2px;
                        margin: 0;

                        small {
                            display: block;
                            font-family: $sans;
                            letter-spacing: 0;
                            font-size: 0.6em;
                            margin: 5px 0 0;
                        }

                        a {
                            color: $text-color;
                        }
                    }

                    p {
                        font-size: 1.1rem;
                        margin: 25px 0 0;
                    }
                }

                &.has-lightbox {
                    padding-bottom: 55px;

                    footer {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        text-align: center;
                        padding: 30px 0;

                        a {
                            font-family: $sans;
                            color: $orange;
                            text-transform: uppercase;
                            font-weight: 700;
                        }
                    }
                }

                &:hover {
                    @include media-breakpoint-up(md) {
                        transform: scale(1.05);
                    }
                }
            }

            + .flip-card {
                @include media-breakpoint-down(md) {
                    margin-top: $section-padding-md;
                }
            }
        }
    }
}
