a,
button,
input[type="submit"] {
    &.btn {
        line-height: 1;
        text-align: center;
        display: inline-block;
        padding: 17px 40px 15px;
        border-radius: $border-radius;
        background-color: $orange;
        color: #fff;
        text-transform: uppercase;
        font-family: $sans;
        font-weight: bold;
        transition: transform 0.5s ease, background-color 0.5s ease;
        text-decoration: none;
        border: 2px solid $orange;
        text-shadow: none;

        &,
        * {
            vertical-align: middle;
        }

        i {
            font-size: 1.6em;
            margin-right: 5px;

            &[class*="gpd-"] {
                line-height: 0;
                position: relative;
                top: -4px;
            }
        }

        &.medium {
            padding: 12px 30px 10px;
        }

        &.small {
            padding: 10px 20px 8px;
            font-size: 0.8em;

            i {
                font-size: 1em;
            }
        }

        &.xsmall {
            padding: 8px 10px 6px;
            font-size: 0.9em;
        }

        &.grey {
            background-color: $dark-grey;
            border-color: $dark-grey;

            &:hover {
                background-color: darken($dark-grey, 10);
                border-color: darken($dark-grey, 10);
            }
        }

        &.transparent {
            background-color: transparent;
            color: $orange;

            &.grey {
                border-color: $medium-grey;
                color: $medium-grey;

                &:hover {
                    border-color: darken($medium-grey, 5);
                    color: darken($medium-grey, 5);
                }
            }

            &.white {
                border-color: #fff;
                color: #fff;

                &:hover {
                    border-color: darken(#fff, 5);
                    color: darken(#fff, 5);
                }
            }

            &:hover {
                background-color: transparent;
                border-color: darken($orange, 5);
            }
        }

        &.full-width {
            display: block;
            width: 100%;
        }

        &:hover {
            background-color: darken($orange, 10);
            border-color: darken($orange, 10);
        }

        &:not(.no-animate) {
            &:hover {
                transform: scale(1.05);
            }
        }

        @include media-breakpoint-down(lg) {
            padding: 13px 32px 11px;
        }

        @include media-breakpoint-down(sm) {
            padding: 13px 15px 11px;
        }
    }
}
