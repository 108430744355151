section.upcoming-events {
    text-align: center;

    h2.title-lg {
        &:after {
            content: "Events";
            right: 0;
            font-size: 8rem;
        }
    }

    figure {
        border-radius: $border-radius $border-radius 0 0;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 100px;

        img {
            width: 100%;
        }
    }

    table {
        background-color: #fff;
        width: 100%;
        text-transform: uppercase;
        margin-bottom: 25px;
        text-align: left;
        border-radius: 0 0 $border-radius $border-radius;

        td,
        th {
            &.location,
            &.date,
            &.time,
            &.fee,
            &.openings {
                text-align: center;

                @include media-breakpoint-down(md) {
                    text-align: left;
                }
            }
        }

        thead {
            background-color: $green;
            color: #fff;

            @include media-breakpoint-down(md) {
                display: none;
            }

            th {
                font-weight: 500;
                font-size: 1.2rem;
                padding: 15px 20px;

                + th {
                    border-left: 2px solid darken($green, 5);
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 25px 30px;
                    font-family: $sans;

                    @include media-breakpoint-down(md) {
                        display: block;
                        width: 100%;
                        padding: 8px 20px;
                    }

                    a {
                        color: $green;
                        text-decoration: underline;
                    }

                    &.nowrap {
                        @include media-breakpoint-up(md) {
                            width: 1%;
                            white-space: nowrap;
                        }
                    }

                    + td {
                        border-left: 2px solid darken(#fff, 14);

                        @include media-breakpoint-down(md) {
                            border: none;
                        }
                    }

                    &:first-child {
                        @include media-breakpoint-down(md) {
                            padding-top: 18px;
                        }
                    }

                    &:last-child {
                        @include media-breakpoint-down(md) {
                            padding-bottom: 15px;
                        }
                    }

                    &:before {
                        @include media-breakpoint-down(md) {
                            content: attr(data-title);
                            border-bottom: 2px solid darken(#fff, 25);
                            font-weight: 700;
                            display: block;
                            margin-bottom: 8px;
                            font-size: 0.85em;
                        }
                    }
                }

                &:nth-child(odd) {
                    background-color: darken(#fff, 12);
                }
            }
        }
    }
}
