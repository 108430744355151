ul.search-results {
    li {
        &.search-result {
            padding: 0;
            background-color: #fff;
            border-radius: $border-radius;
            border-bottom: $border-radius solid $green;

            figure {
                height: 125px;
                background-size: cover;
                background-position: center center;
                border-radius: $border-radius $border-radius 0 0;
                position: relative;
                overflow: hidden;

                a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            article {
                padding: 25px;

                h3 {
                    font-size: 1.2rem;
                }

                small.distance {
                    display: inline-block;
                    background-color: darken($white, 6);
                    padding: 8px 10px 5px;
                    line-height: 1;
                    border-radius: $border-radius;
                    font-family: $sans;
                    font-size: 0.9rem;
                    text-transform: uppercase;
                    vertical-align: middle;
                    margin-bottom: 15px;

                    i {
                        color: $blue;
                    }
                }
            }

            + .search-result {
                margin-top: 25px;
            }
        }
    }
}

h3.no-results {
    text-align: center;
    font-weight: 300;
    font-size: 1.4rem;
    font-family: $sans;
    background-color: $white;
    padding: $section-padding / 2;
    border-radius: $border-radius;
    border-bottom: $border-radius solid $red;
}
