/* Header */

body {
    //padding-top: $header-height;

    @include media-breakpoint-down(lg) {
        //padding-top: 120px;
    }

    @include media-breakpoint-down(md) {
        padding-top: 0;
    }
}

header.main {
    position: relative;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10000;

    @include media-breakpoint-down(md) {
        position: relative;
    }

    .main-menu {
        a.logo {
            position: absolute;
            width: 140px;
            top: -25px;
            display: inline-block;

            @include media-breakpoint-down(lg) {
                width: 100px;
                margin: -10px 0;
                position: relative;
                z-index: 1000;
                top: 0;
            }

            @include media-breakpoint-down(md) {
                margin: 5px 0;
                width: 50px;
                white-space: nowrap;
            }

            &:after {
                content: "Geauga Park District";
                font-weight: 600;
                text-transform: uppercase;
                vertical-align: middle;
                display: inline-block;
                opacity: 1;
                visibility: visible;
                transition: all 0.4s ease;

                @include media-breakpoint-up(lg) {
                    text-align: center;
                    color: $white;
                    font-size: 1.25rem;
                    line-height: 1.1em;
                    margin-top: 3px;
                    text-shadow: 0 0 10px rgba(0, 0, 0, 0.75), 0 0 15px rgba(0, 0, 0, 0.6);
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                }

                @include media-breakpoint-down(lg) {
                    font-size: 1rem;
                }

                @include media-breakpoint-down(md) {
                    margin-left: 8px;
                    color: $text-color;
                }
            }
        }

        nav {
            font-family: $sans;
            font-weight: 600;
            text-transform: uppercase;
            position: relative;

            @include media-breakpoint-down(md) {
                margin-right: 0;
            }

            > ul {
                @include media-breakpoint-down(md) {
                    position: absolute;
                    z-index: 99999999;
                    background-color: darken(#fff, 8);
                    right: 0;
                    padding: 15px 0;
                }

                @include media-breakpoint-down(sm) {
                    width: 100vw;
                }

                > li {
                    float: left;
                    line-height: 75px;
                    font-size: 1.1rem;
                    letter-spacing: 1px;
                    position: relative;

                    @include media-breakpoint-down(lg) {
                        font-size: 0.9rem;
                        line-height: 45px;
                    }

                    @include media-breakpoint-down(md) {
                        line-height: normal;
                        font-size: 1rem;
                        float: none;
                    }

                    a {
                        display: block;
                        padding: 0 25px;
                        color: $text-color;

                        @include media-breakpoint-down(lg) {
                            padding: 15px;
                        }

                        @include media-breakpoint-down(md) {
                            padding: 10px 25px;
                            display: block;
                        }
                    }

                    > ul.sub-menu {
                        position: absolute;
                        list-style: none;
                        margin: 0;
                        padding: 5px 0;
                        background-color: darken(#fff, 8);
                        visibility: hidden;
                        opacity: 0;
                        min-width: 100%;
                        transition: all 0.5s ease;

                        @include media-breakpoint-down(md) {
                            position: static;
                            visibility: visible;
                            opacity: 1;
                            display: none;
                            background-color: darken(#fff, 15);
                        }

                        li {
                            float: none;
                            line-height: normal;
                            font-size: 0.9rem;

                            a {
                                padding: 10px;

                                @include media-breakpoint-down(md) {
                                    padding: 10px 25px;
                                }
                            }

                            &:hover {
                                a {
                                    background-color: rgba(#000, 0.05);
                                }
                            }
                        }
                    }

                    &.menu-item-22,
                    &.menu-item-23 {
                        position: static;

                        ul.sub-menu {
                            @include media-breakpoint-up(md) {
                                min-width: 750px;
                                padding: 10px;
                                column-count: 3;
                                column-span: all;
                                vertical-align: top;
                            }

                            li {
                                @include media-breakpoint-up(md) {
                                    -webkit-column-break-inside: avoid;
                                    page-break-inside: avoid;
                                    break-inside: avoid;
                                }
                            }
                        }
                    }

                    &:hover {
                        a {
                            @include media-breakpoint-up(md) {
                                background-color: darken(#fff, 8);
                                text-decoration: none;
                            }
                        }

                        > ul.sub-menu {
                            @include media-breakpoint-up(md) {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }

        a.mobile-toggle-button {
            line-height: 58px;
            padding: 0 15px;
            transition: background-color 0.4s ease;

            &.on {
                background-color: darken(#fff, 8);
            }
        }

        @include media-breakpoint-down(md) {
            position: relative;
            z-index: 1;
        }
    }

    .top {
        background-color: $green;

        a {
            display: block;
            text-decoration: none;
            padding: 12px 25px;
            color: rgba(#000, 0.5);
            line-height: 1;
            vertical-align: middle;

            @include media-breakpoint-down(lg) {
                padding: 10px 15px;
                line-height: 25px;
            }

            @include media-breakpoint-down(md) {
                padding: 5px 10px;
            }

            &.mobile-toggle-button {
                color: $white;

                &.on {
                    background-color: darken($green, 10);
                }
            }
        }

        nav {
            font-weight: 500;
            text-transform: uppercase;
            position: relative;

            ul {
                @include media-breakpoint-down(md) {
                    float: none;
                    background-color: darken($green, 10);
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 1001;
                }

                li {
                    float: left;
                    position: relative;

                    @include media-breakpoint-down(md) {
                        float: none;
                        font-size: 0.9rem;
                        white-space: nowrap;
                    }

                    ul.sub-menu {
                        display: none;
                        list-style: none;
                        margin: 0;
                        padding: 5px 0;
                        background-color: darken($green, 5);
                        position: absolute;
                        z-index: 1000;
                        white-space: nowrap;

                        li {
                            float: none;

                            a {
                                padding: 10px 15px;
                                line-height: normal;
                            }

                            &:hover {
                                a {
                                    background-color: darken($green, 10);
                                    color: #fff;
                                }
                            }
                        }
                    }

                    &:hover {
                        background-color: darken($green, 5);

                        ul.sub-menu {
                            display: block;
                        }
                    }
                }
            }
        }

        .search,
        .phone {
            img {
                height: 24px;

                @include media-breakpoint-down(md) {
                    height: 18px;
                }
            }
        }

        .search {
            &:hover {
                background-color: darken($green, 5);
            }
        }

        .phone {
            height: 100%;
            font-weight: 600;
            background-color: darken($green, 5);

            @include media-breakpoint-down(md) {
                background-color: transparent;
            }

            a {
                color: #fff;

                img {
                    margin-right: 4px;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            position: relative;
            z-index: 2;
        }
    }

    &.sticky {
        position: fixed;

        a.logo {
            &:after {
                @include media-breakpoint-up(md) {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
}

aside.alert {
    background-color: $yellow;
    padding: 10px 0;

    * {
        line-height: 1;
    }

    &,
    a {
        color: rgba(#000, 0.5);
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }

    h2,
    label,
    a {
        font-size: 1rem;

        @include media-breakpoint-down(md) {
            font-size: 0.9rem;
        }
    }

    h2,
    label {
        font-weight: 300;
        margin: 0;
    }

    h2 {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    a {
        text-decoration: underline;
    }

    label {
        position: relative;
        top: 2px;
    }

    img {
        width: 30px;
        display: inline-block;
        margin-right: 5px;

        @include media-breakpoint-down(md) {
            width: 20px;
        }
    }

    .headline {
        text-align: center;
    }
}
