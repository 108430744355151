body {
    #interstitial {
        $padding: 50px;
        width: 45vw;
        min-width: 600px;
        max-width: 800px;

        @include media-breakpoint-down(md) {
            min-width: 0;
            width: 100%;
        }

        .interstitial-content {
            text-align: center;
            padding: $padding;

            @include media-breakpoint-down(md) {
                padding: ($padding / 2);
            }

            header {
                figure {
                    max-width: 35%;
                    margin: 0 auto;

                    @include media-breakpoint-down(md) {
                        max-width: 75%;
                        margin-bottom: 0;
                    }
                }

                hgroup {
                    text-transform: uppercase;
                    margin: ($padding / 2) 0;

                    h2,
                    h3 {
                        font-weight: 300;
                        margin: 0;
                    }

                    h2 {
                        font-size: 2.5rem;
                        letter-spacing: 0.05em;
                        line-height: 1;
                        color: $green;

                        @include media-breakpoint-down(md) {
                            font-size: 1.8rem;
                        }
                    }

                    h3 {
                        font-size: 1.1rem;
                        font-family: $sans;
                        padding: 0 15%;

                        @include media-breakpoint-down(md) {
                            font-size: 0.9rem;
                            padding: 0;
                        }
                    }
                }

                .interstitial-graphic {
                    margin: 0 0 $padding;
                }
            }

            article {
                .gform_wrapper {
                    max-width: 85%;
                    margin: 0 auto;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                    }

                    form {
                        .gform_body {
                            .gform_fields {
                                padding: 0;

                                .gfield {
                                    padding: 0;
                                    margin: 0;

                                    .ginput_container {
                                        margin: 0;

                                        span:not(.ginput_price) {
                                            margin-bottom: 0;
                                        }

                                        input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
                                        select,
                                        textarea {
                                            padding: 15px 0 5px;
                                            border: none;
                                            border-bottom: 2px solid #ccc;
                                            background-color: transparent;
                                            margin-top: 15px;
                                            font-size: 1.1rem;
                                            @include placeholder {
                                                text-transform: uppercase;
                                                color: lighten($text-color, 15);
                                                opacity: 1;
                                            }

                                            @include media-breakpoint-down(md) {
                                                margin-top: 0;
                                            }

                                            +label {
                                                display: none;
                                            }

                                            &:focus {
                                                border-color: $green;
                                            }
                                        }

                                        input {
                                            &.medium {
                                                width: 100%;
                                            }
                                        }

                                        select {
                                            -webkit-appearance: none;
                                            -moz-appearance: none;
                                            appearance: none;
                                            text-transform: uppercase;
                                            color: lighten($text-color, 15);

                                            option {
                                                padding: 0;
                                            }
                                        }

                                        &.ginput_complex {
                                            margin-top: 0 !important;
                                        }

                                        &.ginput_container_checkbox {
                                            ul.gfield_checkbox {
                                                li {
                                                    float: left;
                                                    width: 50%;
                                                    font-size: 1.2rem;

                                                    @include media-breakpoint-down(md) {
                                                        float: none;
                                                        width: 100%;
                                                        margin-bottom: 5px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .gfield_label {
                                        margin: ($padding / 2) 0 0;
                                        text-transform: uppercase;
                                        font-size: 1.1rem;
                                        font-weight: 300;
                                    }

                                    &.hide-labels {
                                        .gfield_label {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        .gform_footer {
                            input[type="submit"] {
                                display: block;
                                background-color: $orange;
                                color: $white;
                                border-radius: $border-radius;
                                border: none;
                                width: 100%;
                                text-transform: uppercase;
                                padding: 20px 20px 17px;
                                font-family: $sans;
                                line-height: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .featherlight {
        .featherlight-close-icon {
            background-color: #fff;
        }

        .featherlight-content {
            padding: 0;
            overflow-x: hidden;
            overflow-y: auto;
            border: none;
            border-radius: $border-radius;
            -webkit-overflow-scrolling: touch;

            @include media-breakpoint-down(md) {
                max-width: 85%;
            }
        }
    }
}