/* Hero.scss */

.hero {
    position: relative;
    z-index: 1;

    div.slides {
        height: 100%;
        position: relative;

        div.slide {
            figure {
                height: 100%;

                figcaption {
                    color: #fff;
                    text-align: center;
                    padding: 120px 0 150px;

                    @include media-breakpoint-down(lg) {
                        padding: 75px 0;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 90%;
                        margin: 0 auto;
                        padding: 50px 0;
                    }

                    h2 {
                        font-family: $handwritten;
                        text-transform: uppercase;
                        font-size: 6.2rem;
                        font-weight: normal;
                        line-height: 1;
                        margin-bottom: 20px;

                        @include media-breakpoint-down(md) {
                            font-size: 3.6rem;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 3.2rem;
                        }

                        @include media-breakpoint-down(xs) {
                            font-size: 2.4rem;
                        }
                    }

                    h3 {
                        font-family: $sans;
                        font-size: 2.2rem;
                        letter-spacing: 1.6px;
                        font-weight: 600;
                        text-transform: uppercase;
                        line-height: 1;
                        margin-bottom: 30px;

                        @include media-breakpoint-down(sm) {
                            font-size: 1.6rem;
                        }
                    }
                }

                &.photo-credit {
                    &:after {
                        bottom: 40px;

                        @include media-breakpoint-down(sm) {
                            bottom: 20px;
                        }
                    }
                }
            }
        }

        ul.slick-dots {
            list-style: none;
            margin: 0;
            padding: 0;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 50px;
            text-align: center;
            z-index: 5;

            li {
                $dot-size: 20px;
                display: inline-block;

                button {
                    cursor: pointer;
                    font-size: 0;
                    color: rgba($white, 0);
                    height: $dot-size;
                    width: $dot-size;
                    background-color: rgba($white, 0);
                    border: 3px solid $white;
                    border-radius: 100%;
                    padding: 0;
                    margin: 0 8px;
                }

                &.slick-active {
                    button {
                        background-color: $white;
                    }
                }
            }
        }
    }

    figure.transition {
        position: absolute;
        bottom: 0;
        z-index: 2;

        svg {
            path {
                fill: $grey;
            }
        }
    }

    &.hero-lg {
        .slide {
            figure {
                padding: 100px 0 150px;
                //background-attachment: fixed;

                @include media-breakpoint-down(md) {
                    padding: 0;
                }

                figcaption {
                    &.add-watermark {
                        background-image: url("assets/dist/images/hero-caption-badge-bg.png");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        padding: 125px 0;
                    }
                }
            }
        }
    }
}
