section.map {
    padding: 0;
    background-color: $white;
    position: relative;

    figure#mapbox {
        height: 550px;

        @include media-breakpoint-down(md) {
            height: 400px !important;
        }
    }

    aside.parks {
        height: 100%;
        background-color: $white;
        min-height: 600px;

        article {
            padding: $section-padding / 1.5;

            ul {
                li {
                    a {
                        padding: 5px 0;
                        display: block;
                        font-family: $sans;
                    }
                }
            }
        }

        + .map {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;

            @include media-breakpoint-down(md) {
                position: static;
            }

            figure#mapbox {
                height: 100%;

                @include media-breakpoint-down(md) {
                    height: inherit;
                }
            }
        }
    }

    &:last-child {
        margin-bottom: $section-padding * 2;

        @include media-breakpoint-down(md) {
            margin-bottom: $section-padding-md * 2;
        }
    }
}
