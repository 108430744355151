.slideshow {
    $arrow_height: 40px;
    $arrow_height_md: $arrow_height - 25;
    overflow: hidden;

    a.arrow {
        visibility: hidden;
        opacity: 0;
        font-family: $handwritten;
        font-size: $arrow_height;
        position: absolute;
        top: 50%;
        margin-top: -($arrow_height / 2);
        z-index: 2;
        line-height: 1;
        transition: all 0.3s ease;
        color: $white;
        cursor: pointer;
        padding: 15px;
        background-color: $text-color;

        @include media-breakpoint-down(md) {
            font-size: $arrow_height_md;
            margin-top: -($arrow_height_md / 2);
            opacity: 1;
            visibility: visible;
            text-shadow: 0 0 10px rgba(#000, 0.6);
        }

        &.prev {
            left: -10px;
            border-radius: 0 $border-radius $border-radius 0;

            @include media-breakpoint-down(md) {
                left: -5px !important;
            }
        }

        &.next {
            right: -10px;
            border-radius: $border-radius 0 0 $border-radius;

            @include media-breakpoint-down(md) {
                right: -5px !important;
            }
        }

        &:hover {
            opacity: 1;
            color: $white;
        }
    }

    &:hover {
        a.arrow {
            opacity: 0.8;
            visibility: visible;

            &.prev {
                left: -5px;

                &:hover {
                    left: 0;
                    opacity: 1;
                }
            }

            &.next {
                right: -5px;

                &:hover {
                    right: 0;
                    opacity: 1;
                }
            }
        }
    }
}

.slides {
    .slide {
        display: none;

        &:first-child {
            display: block;
        }
    }

    &.slick-initialized {
        .slide {
            display: block;
        }

        &.equal-height {
            .slick-track {
                display: flex;

                .slick-slide {
                    display: flex;
                    height: auto;
                    justify-content: center;
                    flex: 1;
                    flex-flow: column;
                }
            }
        }
    }
}
