
/* IE10+ overrides */ 
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {   
	.slides.slick-initialized.equal-height .slick-track .slick-slide,
	section.activity-carousel div.activities article.activity main,
	section.park-carousel div.parks article.park main{
		display: block;
	}
	
	section.park-carousel div.parks article.park main{position: relative;}
}