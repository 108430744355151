.md-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    max-width: 630px;
    min-width: 320px;
    height: auto;
    z-index: 20000;
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: $border-radius;
    -webkit-overflow-scrolling: touch;

    @include media-breakpoint-down(md) {
        min-width: 0;
        max-width: none;
        width: 80%;
    }

    button.md-close {
        $btn-size: 30px;
        cursor: pointer;
        display: block;
        margin: 0 auto;
        font-size: 0.8em;
        border: none;
        background-color: transparent;
        color: rgba($text-color, 0.75);
        font-family: $sans;
        position: fixed;
        top: 0;
        right: 0;
        height: $btn-size;
        width: $btn-size;
        line-height: $btn-size;
        text-align: center;
    }

    .md-content {
        height: 100%;
        background: $white;
        border-bottom: $border-radius solid $green;

        article {
            text-align: left;
            color: $text-color;
            position: relative;
            margin: 0 auto;
            padding: 30px;
            margin: 0;
            font-weight: 300;
            font-size: 1.15em;

            h3 {
                margin: 0;
                padding: 0.4em;
                text-align: center;
                font-size: 2.4em;
                font-weight: 300;
                opacity: 0.8;
                background: rgba(0, 0, 0, 0.1);
                border-radius: 3px 3px 0 0;
            }

            p {
                margin: 0;
                padding: 10px 0;
            }

            ul {
                margin: 0;
                padding: 0 0 30px 20px;

                li {
                    padding: 5px 0;
                }
            }
        }
    }

    &.md-show {
        visibility: visible;

        .md-content {
        }
    }
}

.md-overlay {
    cursor: pointer;
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 19000;
    opacity: 0;
    background: rgba($dark-grey, 0.8);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;

    &.md-show {
        opacity: 1;
        visibility: visible;
    }
}

.md-effect-8 {
    .md-content {
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform: rotateY(-70deg);
        -moz-transform: rotateY(-70deg);
        -ms-transform: rotateY(-70deg);
        transform: rotateY(-70deg);
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        opacity: 0;
    }

    &.md-modal {
        -webkit-perspective: 1300px;
        -moz-perspective: 1300px;
        perspective: 1300px;
    }

    &.md-show {
        .md-content {
            -webkit-transform: rotateY(0deg);
            -moz-transform: rotateY(0deg);
            -ms-transform: rotateY(0deg);
            transform: rotateY(0deg);
            opacity: 1;
        }
    }
}
