ul.icon-list {
    li {
        display: inline-block;
        margin-right: 8px;

        img {
            width: 50px;
            transition: transform 0.3s ease;
        }

        [title] {
            position: relative;
            display: inline-block;

            &:after {
                $tooltip_size: 125px;
                content: attr(title);
                @include pointer_arrow ("bottom", 10px, rgba(#000,0.8), $border-radius);
                position: absolute;
                padding: 8px;
                line-height: 1.3em;
                min-width: $tooltip_size;
                bottom: 100%;
                left: 50%;
                margin-left: -($tooltip_size / 2);
                margin-bottom: 5px;
                text-align: center;
                font-family: $sans;
                color: #fff;
                font-size: 0.8rem;
                display: none;
                z-index: 9999;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }

                &:after {
                    display: block;
                }
            }
        }
    }

    &.icon-list-sm {
        li {
            img {
                width: 35px;
            }
        }
    }
}
